<script setup lang="ts">
import type { SearchLocationResponseItem, SearchProductsQueryParameters } from '@yescapa-dev/ysc-api-js/legacy'
import { isString } from '@yescapa-dev/ysc-api-js/modern'
import { LOCALE_CODES } from '@yescapa-dev/ysc-components/i18n'
import { YSC_API_SEARCH_ERROR } from '~/utils/error/YscErrorClasses'

interface Props {
  withSubmitButton?: boolean
  extraQuery?: SearchProductsQueryParameters | null
}

const props = withDefaults(defineProps<Props>(), {
  withSubmitButton: false,
  extraQuery: null,
})

const emits = defineEmits<{
  'open-supersizing-modal': [{ query: () => void, redirect: string }]
}>()

const { localeProperties } = useLocaleProperties()
const { $api } = useYscApi()
const { $errorManager } = useErrorManager()
const route = useRoute()

const { storedLastQueries, sanitizeStoredLastQueries } = useStoredLastQueries()

const showLastQueries = ref(true)
const results = ref<SearchLocationResponseItem[]>([])
const countryCode = ref<string | null>(null)

const pendingQuery = ref<Partial<Pick<SearchFilters, 'where' | 'latitude' | 'longitude' | 'date_from' | 'date_to' | 'radius'>>>({
  where: queryValueToString(route.query.where),
  latitude: queryValueToNumber(route.query.latitude),
  longitude: queryValueToNumber(route.query.longitude),
  date_from: queryValueToString(route.query.longitude),
  date_to: queryValueToString(route.query.longitude),
  radius: queryValueToNumber(route.query.radius),
})

const { filters: searchFilters } = storeToRefs(useSearchStore())

const destination = computed(() => pendingQuery.value.where ?? queryValueToString(route.query.where))

const dateStart = computed(() => isString(route.query.date_from) ? fromISOStringWithoutTimeToISOString(route.query.date_from) : null)
const dateEnd = computed(() => isString(route.query.date_to) ? fromISOStringWithoutTimeToISOString(route.query.date_to) : null)

const hasDates = computed(() => !!dateStart.value && !!dateEnd.value)

const onDestinationQueryChange = async (val: string | null) => {
  if (!val || val.trim().length < 3) {
    showLastQueries.value = true
    return
  }

  val = val.trim()

  showLastQueries.value = false

  try {
    results.value = await $api.search.getLocations({
      search: val,
      language: localeProperties.yscCode,
    })
  }
  catch (e) {
    if (e instanceof Error) {
      $errorManager({ e, name: YSC_API_SEARCH_ERROR })
    }
  }
}

const onDestinationChange = async (args: { locationId: string, label: string, countryCode: string } | null) => {
  if (!args) return

  const { locationId, label, countryCode: newCountryCode } = args
  let latitude, longitude, radius
  try {
    const data = await $api.search.getLocationsInfos({ location_id: locationId })
    latitude = data.displayPosition.latitude
    longitude = data.displayPosition.longitude
    radius = data.radius
  }
  catch (e) {
    if (e instanceof Error) {
      $errorManager({ e, name: YSC_API_SEARCH_ERROR })
    }
    return
  }

  pendingQuery.value.where = label
  pendingQuery.value.latitude = latitude
  pendingQuery.value.longitude = longitude
  pendingQuery.value.radius = radius

  storedLastQueries.value = sanitizeStoredLastQueries([{ locationId, label, countryCode: newCountryCode }, ...storedLastQueries.value])

  countryCode.value = newCountryCode

  if (!props.withSubmitButton && hasDates.value) {
    goToQuery()
  }
}

const onDatesChange = (evt: { start?: Date | null, end?: Date | null } | null) => {
  const { start, end } = evt || {}

  pendingQuery.value.date_from = start ? formatDateForQuery(start) : null
  pendingQuery.value.date_to = end ? formatDateForQuery(end) : null

  if (!props.withSubmitButton) {
    const { date_from, date_to, ...query } = route.query
    if (!start && !end) {
      if (!date_from && !date_to) {
        return
      }
      searchFilters.value.date_from = null
      searchFilters.value.date_to = null
      navigateTo({ name: 's', query })
      return
    }
    if (pendingQuery.value.date_from === date_from && pendingQuery.value.date_to === date_to) {
      return
    }
    goToQuery()
  }
}

const onSubmit = () => {
  if (countryCode.value === 'NL') {
    const { localeProperties: { yscCode, domain } } = useLocaleProperties()
    const getDomain = () => {
      switch (yscCode) {
        case LOCALE_CODES.DE:
        case LOCALE_CODES.DE_AT:
        case LOCALE_CODES.DE_CH:
          return 'https://www.goboony.de/campers/'
        case LOCALE_CODES.EN_GB:
        case LOCALE_CODES.EN_IE:
          return 'https://www.goboony.co.uk/campers/'
        case LOCALE_CODES.IT:
        case LOCALE_CODES.IT_CH:
          return 'https://www.goboony.it/campers/'
        case LOCALE_CODES.FR:
        case LOCALE_CODES.FR_CH:
        case LOCALE_CODES.FR_BE:
          return 'https://www.goboony.fr/campers/'
        case LOCALE_CODES.NL:
        case LOCALE_CODES.NL_BE:
          return 'https://www.goboony.nl/campers/'
        default:
          return 'https://www.goboony.com/campers/'
      }
    }
    const parameters = new URLSearchParams({
      country_code: 'nl',
      utm_medium: 'homepage',
      utm_source: domain ?? '',
      utm_campaign: 'netherlands',
    })
    const { where, latitude, longitude, radius, date_from, date_to } = pendingQuery.value
    if (date_from) {
      parameters.append('starts_at', date_from)
    }
    if (date_to) {
      parameters.append('ends_at', date_to)
    }
    if (latitude) {
      parameters.append('latitude', String(latitude))
    }
    if (longitude) {
      parameters.append('longitude', String(longitude))
    }
    if (radius) {
      parameters.append('radius', String(radius))
    }
    if (where) {
      parameters.append('location', where)
    }
    emits('open-supersizing-modal', { query: goToQuery, redirect: `${getDomain()}?${parameters.toString()}` })
    return
  }
  goToQuery()
}

const goToQuery = () => {
  const query = { ...route.query }
  const { where, latitude, longitude, radius, date_from, date_to } = pendingQuery.value

  if (date_from && date_to) {
    query.date_from = date_from
    query.date_to = date_to
  }
  if (where) {
    query.where = where
  }
  if (latitude) {
    query.latitude = latitude.toString()
  }
  if (longitude) {
    query.longitude = longitude.toString()
  }
  if (radius) {
    query.radius = radius.toString()
  }

  query.page = '1'

  if (props.extraQuery) {
    for (const [key, value] of Object.entries(props.extraQuery)) {
      query[key] = value
    }
  }

  navigateTo({ name: 's', query })

  searchFilters.value.where = queryValueToString(query.where)
  searchFilters.value.latitude = queryValueToNumber(query.latitude)
  searchFilters.value.longitude = queryValueToNumber(query.longitude)
  searchFilters.value.radius = queryValueToNumber(query.radius)
  searchFilters.value.date_from = queryValueToString(query.date_from)
  searchFilters.value.date_to = queryValueToString(query.date_to)

  pendingQuery.value = {}
  results.value = []
}

const onEnterKeyPressed = async () => {
  if (!results.value.length) {
    return
  }
  const { locationId, label, countryCode: newCountryCode } = results.value[0]
  await onDestinationChange({ locationId, label, countryCode: newCountryCode })
  if (!props.withSubmitButton) {
    onSubmit()
  }
}
</script>

<template>
  <YscSearch
    :results="showLastQueries ? storedLastQueries : results"
    :destination="destination"
    :date-start="dateStart"
    :date-end="dateEnd"
    :locale="localeProperties.forceLocaleCode"
    :with-submit-button="props.withSubmitButton"
    with-destination-modal-mobile
    with-dates
    v-bind="$attrs"
    @destination-query-change="onDestinationQueryChange"
    @destination-change="onDestinationChange"
    @dates-change="onDatesChange"
    @enter-key-pressed="onEnterKeyPressed"
    @submit="onSubmit"
  />
</template>
