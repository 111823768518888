import { isString } from '@yescapa-dev/ysc-api-js/modern'

export const useStoredLastQueries = () => {
  type LastQuery = { locationId: string, label: string, countryCode: string }

  const isSavedQuery = (v: any): v is LastQuery => {
    if (typeof v !== 'object') return false

    const { locationId, label, countryCode } = v ?? {}

    return isString(locationId) && isString(label) && isString(countryCode)
  }

  const isUnique = (x: LastQuery, i: number, arr: LastQuery[]) => arr.findIndex(y => x.locationId === y.locationId) === i

  // returns a sanitized array of max five unique values
  const sanitizeStoredLastQueries = (unclean: any): LastQuery[] => {
    if (!Array.isArray(unclean)) {
      return []
    }

    return unclean.filter(isSavedQuery)
      .filter(isUnique)
      .slice(0, 5)
  }

  const storedLastQueries = useLocalStorage<LastQuery[]>(
    'ysc-search-last-queries-20231228', // Key was updated following breaking api change on location ids.
    [],
    {
      mergeDefaults: true,
      serializer: {
        read: (v: any) => {
          try {
            return sanitizeStoredLastQueries(JSON.parse(v))
          }
          catch (e) {
            return []
          }
        },
        write: (v: any) => JSON.stringify(v),
      },
    },
  )

  return { storedLastQueries, sanitizeStoredLastQueries }
}
